import { Box, styled } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import type { ActivityHistoryDto } from '@hcr/api/consumer'
import { Chip, unit } from '@hcr/ui'
import { find, flow, getPropertyValue } from '@hcr/utils'

import { useDestinationsQuery } from '../../../../../hooks'
import { DateFormat, LocaleNamespace, Path } from '../../../../../models'
import {
  createActivityHistoryValidityString,
  currencyFormatter,
  dateFormatter,
  isDestinationOf,
  to,
} from '../../../../../utils'

import { HistoryPreview } from './HistoryPreview'
import { HistoryPreviewLoading } from './HistoryPreviewLoading'

interface HistoryPreviewActivityProps {
  activity: ActivityHistoryDto
}

export const HistoryPreviewActivity: FC<HistoryPreviewActivityProps> = ({ activity }) => {
  const { t } = useTranslation()

  const destination = useDestinationsQuery({
    select: flow(getPropertyValue('destinations'), find(isDestinationOf(activity))),
  })

  if (destination.isPending) {
    return <HistoryPreviewLoading />
  }

  return (
    <HistoryPreview
      key={activity.activity_id}
      bookingDate={dateFormatter.format(DateFormat.Standard, activity.booking_date)}
      destinationName={destination.data?.title ?? t('history.holiday-club-resorts')}
      headerPrimary={
        <Header>
          {t(activity.name, { ns: LocaleNamespace.ActivityName })}
          <Chip text={t(activity.status, { ns: LocaleNamespace.TicketStatus })} variant={activity.status} />
        </Header>
      }
      headerSecondary={createActivityHistoryValidityString(activity)}
      link={to([Path.HistoryDetailsActivity, { activityId: String(activity.activity_id) }])}
      price={currencyFormatter.format(activity.total_amount)}
    />
  )
}

const Header = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: ${unit(2)};
  margin-right: ${unit(8)};
`
