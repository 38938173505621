import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, styled, Typography } from '@mui/material'
import type { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { COLORS, ICONS, unit } from '@hcr/ui'

import { LINK_CONFIG } from '../../../../../configs'
import { useLastPath } from '../../../../../contexts'
import { useLocale } from '../../../../../hooks'
import { Path } from '../../../../../models'
import { to } from '../../../../../utils'
import { LayoutBase } from '../../../../common'
import { Link } from '../link'

interface LayoutContextMenuProps {
  cta: ReactNode
  list: ReactNode
}

export const LayoutContextMenu: FC<LayoutContextMenuProps> = ({ cta, list }) => {
  const { t } = useTranslation()
  const lastPath = useLastPath()
  const locale = useLocale()
  const navigate = useNavigate()

  return (
    <LayoutBase>
      <Container>
        <Header>
          <CloseIcon onClick={() => navigate(lastPath.root)}>
            <FontAwesomeIcon icon={ICONS.farXmark} />
          </CloseIcon>
          <Typography variant='uppercaseS'>{t('context-menu.your-bookings')}</Typography>
        </Header>
        <Content>{list}</Content>
        <Footer>
          <Link
            description={t('context-menu.explore-and-book-services')}
            icon={<FontAwesomeIcon icon={ICONS.farArrowRight} />}
            title={t('context-menu.browse-destinations')}
            to={to(Path.ContextMenuDestinations)}
          />
          <Link
            description={t('context-menu.find-your-next-holiday')}
            icon={<FontAwesomeIcon icon={ICONS.farArrowUpRightFromSquare} />}
            isExternal
            title={t('context-menu.book-a-vacation')}
            to={LINK_CONFIG.store[locale.id]}
          />
          <Cta>{cta}</Cta>
        </Footer>
      </Container>
    </LayoutBase>
  )
}

const Container = styled(Box)`
  min-height: 100vh;
  background-color: ${COLORS.hiekka[500]};
  margin-bottom: ${unit(4)};
`

const Header = styled(Box)`
  background-color: ${COLORS.vaalea['60%']};
  padding: 0 0 ${unit(3.5)} ${unit(7)};
`

const Content = styled(Box)`
  background-color: ${COLORS.vaalea['60%']};
`

const CloseIcon = styled(Box)`
  cursor: pointer;
  padding: ${unit(4.5)} ${unit(8)} 0 0;
  text-align: right;
  font-size: ${unit(6)};
`

const Footer = styled(Box)`
  margin-top: ${unit(9)};
`

const Cta = styled(Box)`
  padding: ${unit(9)} ${unit(7)};
`
