import { LogLevel } from '@hcr/utils'

import { LocaleId } from '../models'

import type { EnvironmentVariables } from './types'

export const Environment: EnvironmentVariables = {
  API: {
    Auth: {
      clientId: 'be046ba6-0415-47d9-8cd8-163a4f0f477b',
      domain: 'hcrcustomerb2c.b2clogin.com',
      redirectUrl: 'https://loma.holidayclubresorts.com',
      url: 'https://hcrcustomerb2c.b2clogin.com/hcrcustomerb2c.onmicrosoft.com/B2C_1_pwa_signin',
    },
    Consumer: {
      url: 'https://prod-hcdpcommon-apim.azure-api.net/v1',
      useMocks: false,
    },
    Optimizely: {
      url: 'https://www.holidayclubresorts.com/api/v1/mobile',
      useMocks: false,
    },
    Owners: {
      url: 'https://osakaspalvelu.holidayclubresorts.com',
    },
    Webshop: {
      url: 'https://www.holidayclubresorts.com',
    },
  },
  Analytics: {
    containerId: 'GTM-5T6NGL3',
    useAnalytics: true,
  },
  General: {
    localeId: LocaleId.Fi,
    logLevel: LogLevel.Info,
    name: 'prod',
  },
  Gomeddo: {
    business: 'hcr',
    page: '2a5ac0d8-0519-43cd-a28d-8cc5b686d43a',
  },
  Sentry: {
    dsn: 'https://64cb63b46f76be28ddb8e7f1a40a42f8@o4505834828660736.ingest.sentry.io/4505838048313344',
    tracesSampleRate: 0.1,
    useSentry: true,
  },
}
