import type { DayjsFormat } from '../../models'
import { DateFormat } from '../../models'

import { format as formatDate } from './format'

export const formatDateRange = ({
  from,
  fromFormat = DateFormat.Standard,
  to,
  toFormat = DateFormat.Standard,
}: {
  from: Date | string
  fromFormat?: DayjsFormat
  to: Date | string
  toFormat?: DayjsFormat
}): string => `${formatDate(fromFormat, from)} - ${formatDate(toFormat, to)}`
