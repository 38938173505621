import hash from 'object-hash'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import type { PackageTicketDto } from '@hcr/api/consumer'
import { isNotNull } from '@hcr/utils'

import { useHoliday } from '../../../../../contexts'
import { useAccommodationDetailsQuery, useIdToken } from '../../../../../hooks'
import { LocaleNamespace, Path } from '../../../../../models'
import { createAccommodationGuestsString, createPackageTicketValidityString, to } from '../../../../../utils'

import { TicketPreview } from './TicketPreview'
import { TicketPreviewLoading } from './TicketPreviewLoading'

interface TicketPreviewPackageProps {
  ticket: PackageTicketDto
}

export const TicketPreviewPackage: FC<TicketPreviewPackageProps> = ({ ticket }) => {
  const { t } = useTranslation()
  const idToken = useIdToken()
  const holiday = useHoliday()

  const accommodation = useAccommodationDetailsQuery(
    { idToken: String(idToken), accommodationId: Number(holiday.data.accommodationId) },
    {
      enabled: isNotNull(idToken) && isNotNull(holiday.data.accommodationId),
      retryOnMount: false,
    }
  )

  if (holiday.isError || accommodation.isError) {
    return null
  }

  if (holiday.isSuccess && accommodation.isSuccess) {
    return (
      <TicketPreview
        key={hash(['package', ticket.category_id, ticket.status])}
        guests={createAccommodationGuestsString(accommodation.data)}
        link={to([
          Path.TicketDetailsPackage,
          { categoryId: ticket.category_id },
          { accommodationId: String(accommodation.data.accommodation_id) },
        ])}
        name={t(ticket.category_id, { ns: LocaleNamespace.PriceCategoryId })}
        status={ticket.status}
        type={t('package', { ns: LocaleNamespace.TicketType })}
        validity={createPackageTicketValidityString(ticket.status)}
      />
    )
  }

  return <TicketPreviewLoading />
}
