export enum DateFormat {
  DayOfTheWeekShort = 'ddd',
  Standard = 'l',
}

export enum TimeFormat {
  Standard = 'LT',
}

export enum DateTimeFormat {
  Standard = `${DateFormat.Standard}, ${TimeFormat.Standard}`,
}

export type DayjsFormat = DateFormat | TimeFormat | DateTimeFormat
