import * as dayjs from 'dayjs'

import type { HoldingDto, HoldingWeekDto } from '@hcr/api/consumer'
import { filter, flow, getPropertyValue, sort } from '@hcr/utils'

import { compareDates } from '../../../dates'

export const selectNextHoldingWeeks: (holding: HoldingDto) => HoldingWeekDto[] = flow(
  getPropertyValue('weeks'),
  filter(week => dayjs().isBefore(week.week_start)),
  sort({
    comparer: compareDates,
    asc: getPropertyValue('week_start'),
  })
)
