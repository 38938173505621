import { Button } from '@mui/material'
import type { FC } from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { BookingSummary, LinkUnstyled } from '@hcr/ui'
import { find, flow, getPropertyValue, hasPropertyValue, isNotNull, isNotUndefined, isUndefined } from '@hcr/utils'

import { useActivitiesHistoryQuery, useActivitiesQuery, useDestinationsQuery, useIdToken } from '../../../../hooks'
import type { HistoryDetailsActivityPathParams } from '../../../../models'
import { DateFormat, LocaleNamespace, Path } from '../../../../models'
import { createActivityValidity, currencyFormatter, dateFormatter, isDestinationOf, to } from '../../../../utils'
import { LayoutNavigationBackError404 } from '../../../common'
import { LayoutHistoryDetails, HistoryDetailsTicketLoading } from '../common'

const BACK_NAVIGATION_PATH = to(Path.History)

export const HistoryDetailsActivity: FC = () => {
  const { t } = useTranslation()
  const idToken = useIdToken()
  const params = useParams<HistoryDetailsActivityPathParams>()

  const currentActivity = useActivitiesQuery(
    { idToken: String(idToken) },
    {
      select: find(hasPropertyValue('activity_id', params.activityId)),
      enabled: isNotNull(idToken) && isNotUndefined(params.activityId),
    }
  )

  const isCurrentActivityNotFound =
    currentActivity.isError || (currentActivity.isSuccess && isUndefined(currentActivity.data))

  const historyActivity = useActivitiesHistoryQuery(
    { idToken: String(idToken) },
    {
      select: find(hasPropertyValue('activity_id', params.activityId)),
      enabled: isNotNull(idToken) && isNotUndefined(params.activityId) && isCurrentActivityNotFound,
    }
  )

  const activity = useMemo(
    () => (isCurrentActivityNotFound ? historyActivity : currentActivity),
    [currentActivity, historyActivity, isCurrentActivityNotFound]
  )

  const destination = useDestinationsQuery({
    select: flow(getPropertyValue('destinations'), find(isDestinationOf(activity.data))),
    enabled: isNotUndefined(activity.data),
  })

  if (isUndefined(params.activityId) || activity.isError || (activity.isSuccess && isUndefined(activity.data))) {
    return <LayoutNavigationBackError404 to={BACK_NAVIGATION_PATH} />
  }

  if (activity.isSuccess && isNotUndefined(activity.data)) {
    const validityLabel = t('history-details.booking-time')
    const validity = createActivityValidity(activity.data, validityLabel)

    return (
      <LayoutHistoryDetails
        backNavigationPath={BACK_NAVIGATION_PATH}
        cta={
          activity.data.status !== 'Used' && (
            <Button
              component={LinkUnstyled}
              variant='outlined'
              color='black'
              to={to([
                Path.TicketDetailsActivity,
                { activityId: activity.data.activity_id },
                { resortsIds: String(activity.data.resort_id) },
              ])}
            >
              {t('history-details.open-ticket')}
            </Button>
          )
        }
        headerPrimary={t(activity.data.name, { ns: LocaleNamespace.ActivityName })}
        headerSecondary={t('activity', { ns: LocaleNamespace.TicketType })}
        showFallbackWarning={destination.isError || (destination.isSuccess && isUndefined(destination.data))}
      >
        <BookingSummary
          content={[
            ...(isNotUndefined(destination.data?.title)
              ? [
                  {
                    label: t('history-details.resort'),
                    value: destination.data?.title,
                  },
                ]
              : []),
            {
              label: t('history-details.price'),
              value: currencyFormatter.format(activity.data.total_amount),
            },
            {
              label: t('history-details.status'),
              value: t(activity.data.status, { ns: LocaleNamespace.TicketStatus }),
            },
            {
              label: t('history-details.booking-date'),
              value: dateFormatter.format(DateFormat.Standard, activity.data.booking_date),
            },
            {
              label: validity.label,
              value: validity.date,
            },
            {
              label: t('history-details.payment-status'),
              value: t(activity.data.payment_status, { ns: LocaleNamespace.ActivityPaymentStatus }),
            },
            {
              label: t('history-details.booking-number'),
              value: activity.data.activity_id,
            },
          ]}
        />
      </LayoutHistoryDetails>
    )
  }

  return <HistoryDetailsTicketLoading backNavigationPath={BACK_NAVIGATION_PATH} />
}
