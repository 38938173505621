import type { FC } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { isPriceCategoryIdDto } from '@hcr/api/consumer'
import { find, flow, getPropertyValue, hasPropertyValue, isNotNull, isNotUndefined, isUndefined } from '@hcr/utils'

import { useAccommodationDetailsQuery, useIdToken } from '../../../../hooks'
import type { TicketDetailsPackagePathParams } from '../../../../models'
import { Path, TicketDetailsPackageSearchParamsKeys } from '../../../../models'
import { to } from '../../../../utils'
import { LayoutNavigationBackError404, LayoutNavigationBackLoading } from '../../../common'

import { TicketDetailsPackageActive } from './TicketDetailsPackageActive'
import { TicketDetailsPackageInactive } from './TicketDetailsPackageInactive'

const BACK_NAVIGATION_PATH = to(Path.Booking)

export const TicketDetailsPackage: FC = () => {
  const idToken = useIdToken()
  const params = useParams<TicketDetailsPackagePathParams>()
  const [searchParams] = useSearchParams()

  const categoryIdPathParam = params.categoryId
  const categoryId = isPriceCategoryIdDto(categoryIdPathParam) ? categoryIdPathParam : undefined

  const accommodationIdSearchParam = searchParams.get(TicketDetailsPackageSearchParamsKeys.AccommodationId)
  const accommodationId = isNotNull(accommodationIdSearchParam) ? accommodationIdSearchParam : undefined

  const packageTicket = useAccommodationDetailsQuery(
    { idToken: String(idToken), accommodationId: Number(accommodationId) },
    {
      select: flow(getPropertyValue('package_tickets'), find(hasPropertyValue('category_id', categoryId))),
      enabled: isNotNull(idToken) && isNotUndefined(accommodationId) && isNotUndefined(categoryId),
    }
  )

  if (isUndefined(categoryId) || isUndefined(accommodationId) || packageTicket.isError) {
    return <LayoutNavigationBackError404 to={BACK_NAVIGATION_PATH} />
  }

  if (packageTicket.isSuccess && isNotUndefined(packageTicket.data)) {
    switch (packageTicket.data.status) {
      case 'Active':
        return <TicketDetailsPackageActive accommodationId={Number(accommodationId)} categoryId={categoryId} />
      case 'Inactive':
        return <TicketDetailsPackageInactive accommodationId={Number(accommodationId)} categoryId={categoryId} />
    }
  }

  return <LayoutNavigationBackLoading to={BACK_NAVIGATION_PATH} />
}
