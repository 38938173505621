import { Box, Button } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { LinkUnstyled } from '@hcr/ui'
import { flow, getPropertyValue, find, isNotUndefined, hasPropertyValue, isNotNull, isUndefined } from '@hcr/utils'

import { useDestinationsQuery, useDestinationDetailsQuery, useActivitiesQuery, useIdToken } from '../../../../hooks'
import { DateFormat, LocaleNamespace, Path, TimeFormat } from '../../../../models'
import { dateFormatter, isDestinationOf, to } from '../../../../utils'
import { LayoutNavigationBackError500, LayoutNavigationBackLoading } from '../../../common'
import { LayoutTicketDetailsActive, TicketValidityActive } from '../common'

interface TicketDetailsActivityActiveProps {
  activityId: string
  resortsIds: number[]
}

const BACK_NAVIGATION_PATH = to(Path.Booking)

export const TicketDetailsActivityActive: FC<TicketDetailsActivityActiveProps> = ({ activityId, resortsIds }) => {
  const { t } = useTranslation()
  const idToken = useIdToken()

  const activity = useActivitiesQuery(
    { idToken: String(idToken), resortsIds },
    {
      select: find(hasPropertyValue('activity_id', activityId)),
      enabled: isNotNull(idToken),
    }
  )

  const destination = useDestinationsQuery({
    select: flow(getPropertyValue('destinations'), find(isDestinationOf(activity.data))),
    enabled: isNotUndefined(activity.data),
  })

  const destinationDetails = useDestinationDetailsQuery(
    { destinationId: String(destination.data?.destinationId) },
    { enabled: isNotUndefined(destination.data?.destinationId) }
  )

  if (
    activity.isError ||
    (activity.isSuccess && isUndefined(activity.data)) ||
    destination.isError ||
    (destination.isSuccess && isUndefined(destination.data)) ||
    destinationDetails.isError
  ) {
    return <LayoutNavigationBackError500 to={BACK_NAVIGATION_PATH} />
  }

  if (activity.isSuccess && destination.isSuccess && destinationDetails.isSuccess) {
    if (isNotUndefined(activity.data)) {
      return (
        <LayoutTicketDetailsActive
          additionalInfo={[{ label: t('tickets.resort'), value: destinationDetails.data.name }]}
          backNavigationPath={BACK_NAVIGATION_PATH}
          cta={
            <Button component={LinkUnstyled} to={BACK_NAVIGATION_PATH} variant='outlined' color='black'>
              {t('tickets.back-to-booking')}
            </Button>
          }
          destinationLogoUrl={destinationDetails.data.logoUrl}
          destinationName={destinationDetails.data.name}
          name={t(activity.data.name, { ns: LocaleNamespace.ActivityName })}
          validity={t('tickets.enjoy-your-visit')}
        >
          <TicketValidityActive>
            <Box>{dateFormatter.format(DateFormat.Standard, activity.data.start_date)}</Box>
            <Box>
              {dateFormatter.formatDateRange({
                from: activity.data.start_date,
                fromFormat: TimeFormat.Standard,
                to: activity.data.end_date,
                toFormat: TimeFormat.Standard,
              })}
            </Box>
          </TicketValidityActive>
        </LayoutTicketDetailsActive>
      )
    }

    return <LayoutNavigationBackError500 to={BACK_NAVIGATION_PATH} />
  }

  return <LayoutNavigationBackLoading to={BACK_NAVIGATION_PATH} />
}
