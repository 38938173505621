import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Stack, styled, Typography } from '@mui/material'
import type { FC, ReactNode } from 'react'
import type { To } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { ICONS, LinkUnstyled, unit } from '@hcr/ui'

import { Path } from '../../../../models'
import { to } from '../../../../utils'
import { LayoutBase } from '../../../common'

interface LayoutOnboardingInformationProps {
  button?: {
    label: string
    to: To
  }
  description: string
  headline: string
  image: ReactNode
}

export const LayoutOnboardingInformation: FC<LayoutOnboardingInformationProps> = ({
  button,
  description,
  headline,
  image,
}) => {
  const navigate = useNavigate()

  return (
    <LayoutBase>
      <Container>
        <CloseIcon onClick={() => navigate(to(Path.Home))}>
          <FontAwesomeIcon icon={ICONS.farXmark} />
        </CloseIcon>
        {image}
        <Content>
          <Typography variant='titleM'>{headline}</Typography>
          <Typography variant='bodyM'>{description}</Typography>
        </Content>
        {button && (
          <Button component={LinkUnstyled} to={button.to} variant='contained' color='primary'>
            {button.label}
          </Button>
        )}
      </Container>
    </LayoutBase>
  )
}

const Container = styled(Stack)`
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: ${unit(7)};
  padding: ${unit(7)};
`

const CloseIcon = styled(Box)`
  align-self: flex-end;
  position: absolute;
  top: ${unit(6)};
  right: ${unit(6)};
  cursor: pointer;
  font-size: ${unit(6)};
`

const Content = styled(Stack)`
  align-items: center;
  gap: ${unit(2.5)};
  text-align: center;
`
