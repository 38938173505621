import type { QueryClientConfig } from '@tanstack/react-query'
import * as dayjs from 'dayjs'

import { Environment } from '../environments/environment'
import type { EnvironmentVariables } from '../environments/types'

interface HttpConfig {
  API: Pick<EnvironmentVariables['API'], 'Consumer' | 'Optimizely'>
  query: QueryClientConfig
}

export const HTTP_CONFIG: HttpConfig = {
  API: {
    Consumer: {
      url: Environment.API.Consumer.url,
      useMocks: Environment.API.Consumer.useMocks,
    },
    Optimizely: {
      url: Environment.API.Optimizely.url,
      useMocks: Environment.API.Optimizely.useMocks,
    },
  },
  query: {
    defaultOptions: {
      queries: {
        retry: false,
        // Note: The B2C token is only refreshed when a view is mounted. Re-fetching queries on focus and reconnect may trigger requests with an expired token in some scenarios.
        // Refactoring opportunity: Replace @azure/msal-react and useIdToken() hook with a custom wrapper on @azure/msal-browser and use it to fetch the B2C token in each queryFn.
        refetchOnMount: true,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        staleTime: dayjs.duration({ minutes: 1 }).asMilliseconds(),
      },
    },
  },
}
