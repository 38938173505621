import type { PriceCategoryIdDto, SingleTicketStatusDto } from '@hcr/api/consumer'

export enum Path {
  About = '/about',
  AboutMocks = '/about/mocks',
  Account = '/account',
  AccountEdit = '/account/edit',
  Booking = '/booking',
  BookingDetails = '/booking/details',
  ContextMenu = '/context-menu',
  ContextMenuDestinations = '/context-menu/destinations',
  GomeddoBooking = '/gomeddo/:gomeddoId/booking',
  History = '/history',
  HistoryDetailsAccommodation = '/history/accommodations/:accommodationId/details',
  HistoryDetailsActivity = '/history/activities/:activityId/details',
  HistoryDetailsTicket = '/history/tickets/:ticketId/details',
  Home = '/',
  Onboarding = '/sign-up',
  OnboardingCompletion = '/sign-up/complete',
  Owner = '/owner',
  OwnerBenefitDetails = '/owner/benefits/:benefitId/details',
  OwnerBenefits = '/owner/benefits',
  OwnerDetails = '/owner/:ownerId/details',
  OwnerHoldingBooking = '/owner/holdings/:holdingId/weeks/:weekStart/booking',
  OwnerHoldingDetails = '/owner/holdings/:holdingId/details',
  OwnerHoldingRental = '/owner/holdings/:holdingId/weeks/:weekStart/rental',
  OwnerHoldingWeeks = '/owner/holdings/:holdingId/weeks',
  ServiceDetails = '/destinations/:destinationId/services/:serviceId/details',
  Services = '/destinations/:destinationId/services',
  TicketDetailsActivity = '/tickets/activities/:activityId/details',
  TicketDetailsPackage = '/tickets/packages/:categoryId/details',
  TicketDetailsSingle = '/tickets/singles/:categoryId/details',
}

export const EMPTY_PATH_PARAM = '-' as const

export type PathWithParams =
  | Path.GomeddoBooking
  | Path.HistoryDetailsAccommodation
  | Path.HistoryDetailsActivity
  | Path.HistoryDetailsTicket
  | Path.OwnerBenefitDetails
  | Path.OwnerDetails
  | Path.OwnerHoldingBooking
  | Path.OwnerHoldingDetails
  | Path.OwnerHoldingRental
  | Path.OwnerHoldingWeeks
  | Path.ServiceDetails
  | Path.Services
  | Path.TicketDetailsActivity
  | Path.TicketDetailsPackage
  | Path.TicketDetailsSingle
export type PathWithoutParams = Exclude<Path, PathWithParams>

export type AccommodationIdPathParam = { accommodationId: string }
export type ActivityIdPathParam = { activityId: string }
export type BenefitIdPathParam = { benefitId: string }
export type CategoryIdPathParam = { categoryId: PriceCategoryIdDto }
export type DestinationIdPathParam = { destinationId: string | EmptyPathParam }
export type EmptyPathParam = typeof EMPTY_PATH_PARAM
export type GomeddoIdPathParam = { gomeddoId: string }
export type HoldingIdPathParam = { holdingId: string }
export type OwnerIdPathParam = { ownerId: string }
export type ServiceIdPathParam = { serviceId: string }
export type TicketIdPathParam = { ticketId: string }
export type WeekStartPathParam = { weekStart: string }

export type GomeddoBookingPathParams = GomeddoIdPathParam

export type HistoryDetailsAccommodationPathParams = AccommodationIdPathParam
export type HistoryDetailsActivityPathParams = ActivityIdPathParam
export type HistoryDetailsTicketPathParams = TicketIdPathParam

export enum OnboardingCompletionSearchParamsKeys {
  Nonce = 'nonce',
}
export type OnboardingCompletionSearchParams = {
  [OnboardingCompletionSearchParamsKeys.Nonce]: string
}

export type OwnerBenefitDetailsPathParams = BenefitIdPathParam
export type OwnerDetailsPathParams = OwnerIdPathParam
export type OwnerHoldingBookingPathParams = HoldingIdPathParam & WeekStartPathParam
export type OwnerHoldingDetailsPathParams = HoldingIdPathParam
export type OwnerHoldingRentalPathParams = HoldingIdPathParam & WeekStartPathParam
export type OwnerHoldingWeeksPathParams = HoldingIdPathParam

export type ServiceDetailsPathParams = DestinationIdPathParam & ServiceIdPathParam
export type ServicesPathParams = DestinationIdPathParam

export type TicketDetailsActivityPathParams = ActivityIdPathParam
export enum TicketDetailsActivitySearchParamsKeys {
  ResortsIds = 'resortsIds',
}
export type TicketDetailsActivitySearchParams = {
  [TicketDetailsActivitySearchParamsKeys.ResortsIds]: string
}

export type TicketDetailsPackagePathParams = CategoryIdPathParam
export enum TicketDetailsPackageSearchParamsKeys {
  AccommodationId = 'accommodationId',
}
export type TicketDetailsPackageSearchParams = {
  [TicketDetailsPackageSearchParamsKeys.AccommodationId]: string
}

export type TicketDetailsSinglePathParams = CategoryIdPathParam
export enum TicketDetailsSingleSearchParamsKeys {
  ResortsIds = 'resortsIds',
  Status = 'status',
}
export type TicketDetailsSingleSearchParams = {
  [TicketDetailsSingleSearchParamsKeys.ResortsIds]: string
  [TicketDetailsSingleSearchParamsKeys.Status]: SingleTicketStatusDto
}
