import { z } from 'zod'

import { DoubleDtoSchema, Int32DtoSchema, StringDtoSchema } from '@hcr/api/shared'

import { AccommodationStatusDtoSchema } from './AccommodationStatusDto'
import { PackageTicketDtoSchema } from './PackageTicketDto'
import { PaymentStatusDtoSchema } from './PaymentStatusDto'
import { PriceDtoSchema } from './PriceDto'
import { RoomDtoSchema } from './RoomDto'

export const AccommodationDtoSchema = z.strictObject({
  accommodation_id: Int32DtoSchema,
  resort_id: Int32DtoSchema,
  status: AccommodationStatusDtoSchema,
  number_of_rooms: Int32DtoSchema,
  room_type: StringDtoSchema,
  room_type_id: Int32DtoSchema,
  days: Int32DtoSchema,
  start_date: StringDtoSchema.datetime(),
  end_date: StringDtoSchema.datetime(),
  booking_date: StringDtoSchema.datetime(),
  adults: Int32DtoSchema,
  children: Int32DtoSchema,
  total_amount: DoubleDtoSchema,
  prepaid_amount: DoubleDtoSchema,
  payment_status: PaymentStatusDtoSchema,
  rooms: z.array(RoomDtoSchema),
  prices: z.array(PriceDtoSchema),
  package_tickets: z.array(PackageTicketDtoSchema),
})

export type AccommodationDto = z.infer<typeof AccommodationDtoSchema>

export const isAccommodationDto = (x: unknown): x is AccommodationDto => AccommodationDtoSchema.safeParse(x).success
