import { z } from 'zod'

import { DoubleDtoSchema } from '@hcr/api/shared'

import { PriceCategoryIdDtoSchema } from './PriceCategoryIdDto'

export const PriceDtoSchema = z.strictObject({
  category_id: PriceCategoryIdDtoSchema,
  amount: DoubleDtoSchema,
})

export type PriceDto = z.infer<typeof PriceDtoSchema>
