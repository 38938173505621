import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Stack } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import type { PriceCategoryIdDto } from '@hcr/api/consumer'
import { ICONS, InformationBanner, LinkUnstyled, unit } from '@hcr/ui'
import { find, flow, getPropertyValue, isNotNull, isNotUndefined } from '@hcr/utils'

import { useAccommodationDetailsQuery, useDestinationsQuery, useIdToken } from '../../../../hooks'
import { LocaleNamespace, Path } from '../../../../models'
import {
  createAccommodationGuestsString,
  createPackageTicketValidityString,
  isDestinationOf,
  to,
} from '../../../../utils'
import { LayoutNavigationBackError500, LayoutNavigationBackLoading } from '../../../common'
import { LayoutTicketDetailsInactive } from '../common'

interface TicketDetailsPackageInactiveProps {
  accommodationId: number
  categoryId: PriceCategoryIdDto
}

const BACK_NAVIGATION_PATH = to(Path.Booking)

export const TicketDetailsPackageInactive: FC<TicketDetailsPackageInactiveProps> = ({
  accommodationId,
  categoryId,
}) => {
  const { t } = useTranslation()
  const idToken = useIdToken()

  const accommodation = useAccommodationDetailsQuery(
    { idToken: String(idToken), accommodationId },
    { enabled: isNotNull(idToken) }
  )

  const destination = useDestinationsQuery({
    select: flow(getPropertyValue('destinations'), find(isDestinationOf(accommodation.data))),
    enabled: isNotUndefined(accommodation.data),
  })

  if (accommodation.isError) {
    return <LayoutNavigationBackError500 to={BACK_NAVIGATION_PATH} />
  }

  if (accommodation.isSuccess) {
    return (
      <LayoutTicketDetailsInactive
        additionalInfo={
          isNotUndefined(destination.data) ? [{ label: t('tickets.resort'), value: destination.data.title }] : []
        }
        cta={
          <Button component={LinkUnstyled} to={BACK_NAVIGATION_PATH} variant='outlined' color='black'>
            {t('tickets.back-to-booking')}
          </Button>
        }
        backNavigationPath={BACK_NAVIGATION_PATH}
        guests={createAccommodationGuestsString(accommodation.data)}
        name={t(categoryId, { ns: LocaleNamespace.PriceCategoryId })}
        type={t('package', { ns: LocaleNamespace.TicketType })}
        validity={createPackageTicketValidityString('Inactive')}
      >
        <Stack gap={unit(1.5)} marginTop={unit(5.5)}>
          <InformationBanner
            header={t('tickets.activation')}
            description={t('tickets.automatic-activation')}
            icon={<FontAwesomeIcon icon={ICONS.falCalendarCheck} fontSize={unit(6)} />}
          />
        </Stack>
      </LayoutTicketDetailsInactive>
    )
  }

  return <LayoutNavigationBackLoading to={BACK_NAVIGATION_PATH} />
}
