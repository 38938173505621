import { z } from 'zod'

import { PackageTicketStatusDtoSchema } from './PackageTicketStatusDto'
import { PriceCategoryIdDtoSchema } from './PriceCategoryIdDto'

export const PackageTicketDtoSchema = z.strictObject({
  category_id: PriceCategoryIdDtoSchema,
  status: PackageTicketStatusDtoSchema,
})

export type PackageTicketDto = z.infer<typeof PackageTicketDtoSchema>

export const isPackageTicketDto = (x: unknown): x is PackageTicketDto => PackageTicketDtoSchema.safeParse(x).success
