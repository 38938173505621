import * as dayjs from 'dayjs'

import type { HoldingWeekDto } from '@hcr/api/consumer'
import { flow, group, sort } from '@hcr/utils'

import { compareDates } from '../../../../../utils'

export const prepareWeekPreviewsData = flow(
  sort<HoldingWeekDto>({
    comparer: compareDates,
    asc: 'week_start',
  }),
  group(week => String(dayjs(week.week_start).year()))
)
