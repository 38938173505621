import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion, AccordionDetails, AccordionSummary, Box, styled, Typography } from '@mui/material'
import type { FC } from 'react'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import type { PriceDto } from '@hcr/api/consumer'
import { Alert, COLORS, ICONS, LinkTrans, unit } from '@hcr/ui'

import { LocaleNamespace, Path } from '../../../models'
import { currencyFormatter, to } from '../../../utils'

interface BookingSummaryPriceProps {
  items: PriceDto[]
  total: number
}

export const BookingSummaryPrice: FC<BookingSummaryPriceProps> = ({ items, total }) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState<boolean>(false)

  return (
    <StyledAccordion
      disableGutters
      elevation={0}
      square
      expanded={expanded}
      onChange={(_, expanded) => setExpanded(expanded)}
    >
      <StyledAccordionSummary expandIcon={<FontAwesomeIcon icon={expanded ? ICONS.fassMinus : ICONS.fassPlus} />}>
        <Typography variant='titleM'>{t('booking-summary-price.price-breakdown')}</Typography>
      </StyledAccordionSummary>
      {items.map(({ amount, category_id }) => (
        <StyledAccordionDetails key={category_id}>
          <Typography variant='bodyM'>
            {t(category_id, { ns: LocaleNamespace.PriceCategoryId })}{' '}
            {amount === 0 && t('booking-summary-price.included-in-price')}
          </Typography>
          <Value variant='bodyL'>{currencyFormatter.format(amount)}</Value>
        </StyledAccordionDetails>
      ))}
      <Total>
        <Typography variant='uppercaseS' fontWeight='bold'>
          {t('booking-summary-price.total')}
        </Typography>
        <TotalValue variant='bodyL'>{currencyFormatter.format(total)}</TotalValue>
      </Total>
      <Box marginTop={unit(7)} marginBottom={unit(13)}>
        <Alert
          severity='info'
          title={t('booking-summary-price.info')}
          description={
            <Trans i18nKey='booking-summary-price.additional-services-info-can-be-found'>
              Additional services which you’ve bought after booking your room can be found from the
              <LinkTrans to={to(Path.Booking)} color={COLORS.semantic.info}>
                booking view
              </LinkTrans>
            </Trans>
          }
        />
      </Box>
    </StyledAccordion>
  )
}

const StyledAccordion = styled(Accordion)`
  background-color: ${COLORS.vaalea['60%']};
  padding: 0 ${unit(5)};
`

const StyledAccordionSummary = styled(AccordionSummary)`
  padding: ${unit(2)} 0;
  margin: 0;

  svg {
    width: ${unit(5)};
    height: ${unit(5)};
    color: ${COLORS.black};
  }
`

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: ${unit(3)} 0;
  margin: 0 auto;
  display: grid;
  grid-template-columns: calc(70% - ${unit(2)}) 30%;
  column-gap: ${unit(2)};

  &:not(:last-of-type) {
    border-bottom: thin dashed ${COLORS.vaalea[800]};
  }
`

const Value = styled(Typography)`
  text-align: right;
  word-break: break-word;
`

const Total = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: ${unit(2)};
  margin-top: ${unit(3)};
`

const TotalValue = styled(Typography)`
  text-align: right;
  word-break: break-word;
  font-weight: bold;
  color: ${COLORS.burgundi[500]};
`
