import * as dayjs from 'dayjs'
import * as duration from 'dayjs/plugin/duration'
import * as timezone from 'dayjs/plugin/timezone'
import * as utc from 'dayjs/plugin/utc'

import type { Locale } from '../models'

interface CreateDayjsLoaderOptions {
  locale: Locale
}

export const createDayjsLoader = async ({ locale }: CreateDayjsLoaderOptions) => {
  dayjs.extend(utc)
  dayjs.extend(duration)
  dayjs.extend(timezone)
  dayjs.tz.setDefault()

  await import(
    /* webpackMode: "lazy" */
    /* webpackInclude: /(en|fi).js/ */
    `dayjs/locale/${locale.lang}.js`
  )
  dayjs.locale(locale.lang)
}
