import { Box, styled } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import type { SingleTicketDto } from '@hcr/api/consumer'
import { Chip, unit } from '@hcr/ui'
import { find, flow, getPropertyValue } from '@hcr/utils'

import { useDestinationsQuery } from '../../../../../hooks'
import { DateFormat, LocaleNamespace, Path } from '../../../../../models'
import { currencyFormatter, dateFormatter, isDestinationOf, to } from '../../../../../utils'

import { HistoryPreview } from './HistoryPreview'
import { HistoryPreviewLoading } from './HistoryPreviewLoading'

interface HistoryPreviewTicketProps {
  ticket: SingleTicketDto
}

export const HistoryPreviewTicket: FC<HistoryPreviewTicketProps> = ({ ticket }) => {
  const { t } = useTranslation()

  const destination = useDestinationsQuery({
    select: flow(getPropertyValue('destinations'), find(isDestinationOf(ticket))),
  })

  if (destination.isPending) {
    return <HistoryPreviewLoading />
  }

  return (
    <HistoryPreview
      key={ticket.ticket_id}
      bookingDate={dateFormatter.format(DateFormat.Standard, ticket.activation_possible_from_date)}
      destinationName={destination.data?.title ?? t('history.holiday-club-resorts')}
      headerPrimary={
        <Header>
          {t(ticket.category_id, { ns: LocaleNamespace.PriceCategoryId })}
          <Chip text={t(ticket.status, { ns: LocaleNamespace.TicketStatus })} variant={ticket.status} />
        </Header>
      }
      headerSecondary={t(ticket.ticket_group, { ns: LocaleNamespace.TicketGroup })}
      link={to([Path.HistoryDetailsTicket, { ticketId: String(ticket.ticket_id) }])}
      price={currencyFormatter.format(ticket.price)}
    />
  )
}

const Header = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: ${unit(2)};
  margin-right: ${unit(8)};
`
