import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Stack, styled, Typography } from '@mui/material'
import type { FC, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

import type { StepperProps } from '@hcr/ui'
import { ICONS, Stepper, unit } from '@hcr/ui'

import { Path } from '../../../../models'
import { to } from '../../../../utils'
import { LayoutBase } from '../../../common'

interface LayoutOnboardingProps {
  description: string
  footer: ReactNode
  form: ReactNode
  headline: string
  stepper: StepperProps
}

export const LayoutOnboarding: FC<LayoutOnboardingProps> = ({ description, footer, form, headline, stepper }) => {
  const navigate = useNavigate()
  const { highlight, of } = stepper

  return (
    <LayoutBase>
      <Container>
        <CloseIcon onClick={() => navigate(to(Path.Home))}>
          <FontAwesomeIcon icon={ICONS.farXmark} />
        </CloseIcon>
        <Stepper highlight={highlight} of={of} />
        <Stack gap={unit(4)} textAlign='center'>
          <Typography variant='headlineL'>{headline}</Typography>
          <Typography variant='bodyM'>{description}</Typography>
        </Stack>
        <Box width='100%'>{form}</Box>
        <Stack width='100%' alignItems='center'>
          {footer}
        </Stack>
      </Container>
    </LayoutBase>
  )
}

const Container = styled(Stack)`
  align-items: center;
  gap: ${unit(6)};
  padding: ${unit(6)};
`

const CloseIcon = styled(Box)`
  align-self: flex-end;
  cursor: pointer;
  font-size: ${unit(6)};
`
